import useEffectOnce from "@/lib/useEffectOnce"
import useLocalStorage from "@/lib/useLocalStorage"
import { useToast } from "@/components/ui/use-toast"

export default function Home() {
  const { toast } = useToast()
  const [lastSync, _] = useLocalStorage('lastSyncTimestamp', 0)

  useEffectOnce(() => {
    if (!lastSync) {
      setTimeout(() => {
        toast({
          title: 'Welcome!',
          description: 'All data is stored in local, sync database to START :)',
        })
      }, 1000)
    }
  })

  return (
    <></>
  )
}
